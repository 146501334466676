
import './ElmCommon/bigo.css';
import './ElmCommon/main.css';
import { Elm } from './Main.elm';
import * as serviceWorker from './serviceWorker';


var app = Elm.Main.init({node: document.getElementById("root")});


let hostname = location.hostname;
let port = location.port;
port = "8000"
let host_port = hostname + ":" + port
app.ports.gotHostname.send(host_port);


// Testing locally:
if(hostname=="localhost")
{
    port = "8081"
    host_port = hostname + ":" + port
    var ws = new WebSocket("ws://" + host_port + "/api/cloud/ws-stream");
 }

// On Server:
else {
    var ws = new WebSocket("wss://" + hostname + "/api/cloud/ws-stream");
}


ws.onmessage = function(message) {
    var data_in = JSON.parse(message.data);

    var data_msg = {
        "tag":"WebSocketMsgInData",
        "contents" : data_in
    }
    console.log(message);
    app.ports.websocketIn.send(JSON.stringify(data_msg));
};

ws.onerror = function(message) {
     var data_msg = { "tag":"WebSocketMsgInOnError", "contents" : "" }
     console.log("index.js - WS-Error");
     app.ports.websocketIn.send(JSON.stringify(data_msg));
};
ws.onclose = function(message) {
     console.log("index.js - WS-OnClose");
     var data_msg = { "tag":"WebSocketMsgInOnClose", "contents" : "" }
     app.ports.websocketIn.send(JSON.stringify(data_msg));
};





app.ports.websocketOut.subscribe(function(msg) {
    console.log(msg);
        ws.send(msg);

    });


















































// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
